import React from 'react';
import logo from '../src/images/logo-1.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>-- Conversor Web --</h1>
        Em breve
      </header>
    </div>
  );
}

export default App;
